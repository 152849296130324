import { createModal } from '../modal/index';
import { getCreativeId } from '../../features/xandr';
import { getSiteConfig } from '../../config/site-config';
import { type AstAdObj } from '../../interface/ast';
import { type cogwheelType } from 'interface/cogwheel';
import { createCogwheelButton } from './cogwheel';

const decoratedAds: number[] = [];

const decorateAds = (): void => {
  const { requests: { tags = {} } = {} } = window.apntag;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const targetIds = Object.keys(tags);

  targetIds.forEach((tag) => setupButton(tag));
};

const tryToAppendCogwheelToIframe = (targetId: string): void => {
  const adWrapper = document.getElementById(targetId);

  if (!adWrapper) {
    console.log(targetId, 'Ad wrapper not found, cannot append cogwheel');

    return;
  }

  const adIframe = adWrapper.querySelector('iframe');

  if (!adIframe) {
    console.log(targetId, 'Ad iframe not found, cannot append cogwheel');

    return;
  }

  const siteConfig = getSiteConfig();
  appendCogWheel(targetId, adWrapper, 'iframe', siteConfig.customColor);
};

const shouldAppendCogWheel = (element: Element): boolean => {
  return element && !element.classList.contains('disable-cogwheel');
};

const removeOldCogWheel = (id: string): void => {
  const oldCogWheel = document.getElementById(id);

  if (oldCogWheel) {
    oldCogWheel.remove();
  }
};

const appendCogWheel = (
  targetId: string,
  containerElement: HTMLElement,
  type: cogwheelType,
  customColor?: string
): void => {
  const cogWheelElement = createCogwheelButton(targetId, type, customColor);
  removeOldCogWheel(cogWheelElement.id);

  const { handleClick } = createModal().init();
  containerElement.addEventListener('click', () => handleClick(cogWheelElement));

  containerElement.appendChild(cogWheelElement);
  const creativeId = getCreativeId(targetId);
  decoratedAds.push(creativeId);
};

const setupButton = (targetId: string): void => {
  const { adResponse: { ads = [] } = {} } = window.apntag.requests.tags[targetId];

  if (ads.length < 1) {
    return;
  }

  const predefinedElement: HTMLElement = document.getElementById(`${targetId}-button`);

  if (!shouldAppendCogWheel(document.getElementById(targetId))) {
    return;
  }

  if (predefinedElement) {
    const siteConfig = getSiteConfig();
    const color = predefinedElement?.dataset.customColor || siteConfig.customColor;

    appendCogWheel(targetId, predefinedElement, 'predefinedElement', color);
  } else {
    tryToAppendCogwheelToIframe(targetId);
  }
};

const initCogwheel = ({ targetId, creativeId }: Partial<AstAdObj>): void => {
  const isDecorated = decoratedAds.find((id) => id === creativeId);

  if (targetId && creativeId && !isDecorated) {
    setupButton(targetId);
  }
};

export { initCogwheel, decorateAds, setupButton };
